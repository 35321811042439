<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-modal
            v-show="!show"
            id="update_customer"
            ref="update_customer"
            :title="$parent.lang.update_customer"
            size="lg"
            :footer-bg-variant="`warning`"
            :header-bg-variant="`dark`"
            :header-text-variant="`light`"
            lazy
        >
            <template #modal-header >
                <div class="w-100 flexit" style="direction:rtl;text-align:center">
                    <div style="margin-bottom:5px!important;font-size:1.3rem;border:2px solid #fff !important;width:150px;display:flex;justify-content: center;justify-items: center;align-items: center;">
                        {{ $parent.lang.update_customer }}
                    </div>
                </div>
            </template>
        <div class="px-3 py-2" style="direction:rtl">
            <div class="tobbuttom backBlack" :style="`direction:${$parent.lang.dir};padding:7px;width:100%;text-align:center;`">
                <b-button :style="`border-radius:0;background:transparent;color:#fff;border:none;`">{{$parent.lang.customer_type}}</b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="_cInd" :class="`dispFlex btn btn-small redColor borderRad_`+$parent.lang.algin" @click="changeCusType(0)" >{{$parent.lang.individual_client}} <i id="_indiv"  class="fas fa-arrow-down"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="_cCom" :class="`dispFlex btn btn-small weightColor borderRad_none`" @click="changeCusType(1)" >{{$parent.lang.company_clinet}} <i id="_comps"  class="fas fa-arrow-down hideme"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="_cGov" :class="`dispFlex btn btn-small weightColor borderRad_`+$parent.lang.lalgin" @click="changeCusType(2)" >{{$parent.lang.government_clinet}} <i id="_goves" class="fas fa-arrow-down hideme"></i></b-button>
            </div>
            <v-row>
                <v-col cols="12" md="2" sm="12">
                    <label>{{$parent.lang.customerid}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.customerid"
                        readonly
                        />
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{$parent.lang.mobile}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.mobile"
                        type="number"
                        readonly
                        />
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{$parent.lang.additional_mobile}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.mobile1"
                        type="number"
                        />
                </v-col>
                <v-col cols="12" md="4" sm="12">
                    <label>{{$parent.lang.customer_name}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.full_name"
                        :rules="nameRules"
                        />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{$parent.lang.company_name}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.company_name"
                        />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{$parent.lang.company_vatid}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.company_vatid"
                        />
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{$parent.lang.building_no}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.building_no"
                        />
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{$parent.lang.street_name}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.street_name"
                        />
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{$parent.lang.branch_number}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.branch_number"
                        />
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{$parent.lang.district}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.district"
                        />
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{$parent.lang.zipcode}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.zipcode"
                        />
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{$parent.lang.city}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.city"
                        />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{$parent.lang.address}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.address"
                        />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showGov">
                    <label>{{$parent.lang.entity_name}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.entity_name"
                        />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showGov">
                    <label>{{$parent.lang.branch_name}}</label>
                    <b-form-input class="inborder"
                        v-model="customers.branch_name"
                        />
                </v-col>
            </v-row>
        </div>
        <template #modal-footer="{ close }" >
                <div class="w-100" style="direction:rtl;text-align:center;">
                    <b-button type="button" @click='addCustomer()' style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.save }}</b-button>
                    <b-button type="button" @click='close()' style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.close }}</b-button>
                </div>
            </template>
        </b-modal>
        
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>


<script>
import axios from 'axios';
export default{
    data: () => ({
        customers:{
            id: '',
            mobile: '',
            mobile1: '',
            full_name: '',
            company_name: '',
            company_vatid: '',
            building_no: '',
            street_name: '',
            branch_number: '',
            district: '',
            zipcode: '',
            city:'',
            address:'',
            customer_type: 1,
            entity_name: '',
            branch_name: ''
        },
        showCom: false,
        showGov: false,
    }),
    computed: {
        mobileRules: function() {
            return [
                v => !!v || this.$parent.lang.required_field,
                v => (v && v.length <= 10) || this.$parent.lang.mobil_10_digits,
            ]
        },
        nameRules: function() {
            return [
                v => !!v || this.$parent.lang.required_field,
            ]
        }
    }, 
    methods: {
        firstUpdate(){
            // // console.log("customer_type1",this.customers);
            if(parseFloat(this.customers.customer_type) == 1) this.changeCusType(0);
            else if(parseFloat(this.customers.customer_type) == 2) this.changeCusType(1);
            else if(parseFloat(this.customers.customer_type) == 3) this.changeCusType(2);
        },
        changeCusType(id){
            // // console.log("customer_type",this.customers);
            if(id == 0){
                this.customers.customer_type = 1;
                this.showCom = false;
                this.showGov = false;
                document.getElementById('_indiv').classList.remove('hideme');
                document.getElementById('_comps').classList.add('hideme');
                document.getElementById('_goves').classList.add('hideme');

                document.getElementById('_cInd').classList.remove('redColor');
                document.getElementById('_cCom').classList.remove('redColor');
                document.getElementById('_cGov').classList.remove('redColor');

                document.getElementById('_cInd').classList.add('redColor');
                document.getElementById('_cCom').classList.add('weightColor');
                document.getElementById('_cGov').classList.add('weightColor');

            }else if(id == 1){
                this.customers.customer_type = 2;
                this.showCom = true;
                this.showGov = false;
                document.getElementById('_comps').classList.remove('hideme');
                document.getElementById('_indiv').classList.add('hideme');
                document.getElementById('_goves').classList.add('hideme');

                document.getElementById('_cInd').classList.remove('redColor');
                document.getElementById('_cCom').classList.remove('redColor');
                document.getElementById('_cGov').classList.remove('redColor');

                document.getElementById('_cInd').classList.add('weightColor');
                document.getElementById('_cCom').classList.add('redColor');
                document.getElementById('_cGov').classList.add('weightColor');

            }
            else if(id == 2){
                this.customers.customer_type = 3;
                this.showCom = false;
                this.showGov = true;
                document.getElementById('_goves').classList.remove('hideme');
                document.getElementById('_comps').classList.add('hideme');
                document.getElementById('_indiv').classList.add('hideme');

                document.getElementById('_cInd').classList.remove('redColor');
                document.getElementById('_cCom').classList.remove('redColor');
                document.getElementById('_cGov').classList.remove('redColor');

                document.getElementById('_cInd').classList.add('weightColor');
                document.getElementById('_cCom').classList.add('weightColor');
                document.getElementById('_cGov').classList.add('redColor');

            }
            // this.changeCtype();
        },
        addCustomer(id){
            // // // console.log();
            if(!this.$refs.form.validate()){
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "updateCustomer");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",this.customers.id)
            post.append("data[mobile]",this.customers.mobile)
            post.append("data[mobile1]",this.customers.mobile1)
            post.append("data[full_name]",this.customers.full_name)
            post.append("data[company_name]",this.customers.company_name);
            post.append("data[company_vatid]",this.customers.company_vatid);
            post.append("data[building_no]",this.customers.building_no);
            post.append("data[street_name]",this.customers.street_name);
            post.append("data[branch_number]",this.customers.branch_number);
            post.append("data[district]",this.customers.district);
            post.append("data[zipcode]",this.customers.zipcode);
            post.append("data[city]",this.customers.city);
            post.append("data[address]",this.customers.address);
            post.append("data[entity_name]",this.customers.entity_name);
            post.append("data[branch_name]",this.customers.branch_name);
            post.append("data[customer_type]",this.customers.customer_type);
            post.append("updatetype",id);
            axios.post(
                this.$SAMCOTEC.r_path , post
            ).then((response) => {
                var res = response.data;
                if(res.error.number == 200){
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    this.$snotify.success(this.$parent.lang.customer_addedd, '', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                    
                    this.$parent.getClients();
                    document.getElementById('hidaddcus').click();
                }else{
                    this.$snotify.error(res.error.customer_updated, '', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                }
            })
        }
    },
}
</script>